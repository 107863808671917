import React from 'react';
import './App.css';
import FlyttfirmaLogo from './logo.svg';

const PRICE_PER_CUBIC_METER = 495;
const PRICE_PER_KILOMETER = 3;
const SQUARE_FEET_PRICE = 110;

class App extends React.Component {
  /**
   * @author SimZor
   * @edited 2020-01-02 15:00
   */
  constructor(props) {
    super(props);

    this.state = {
      fromAddress: '',
      toAddress: '',
      fromAddressPlace: null,
      toAddressPlace: null,
      squareFeet: 0,
      withRut: false,
      selectedPropertyType: '',
      kilometersBetweenPlaces: 0,
      calculate: false,
    };

    this.fromAddressRef = React.createRef();
    this.toAddressRef = React.createRef();
  }

  /**
   * @author SimZor
   * @edited 2020-01-02 15:00
   */
  componentDidMount() {
    this.autocompleteFromAddress = new window.google.maps.places.Autocomplete(
      this.fromAddressRef.current, {
        componentRestrictions: { country: 'SE' },
        types: ['address'],
      },
    );
    this.autocompleteToAddress = new window.google.maps.places.Autocomplete(
      this.toAddressRef.current, {
        componentRestrictions: { country: 'SE' },
        types: ['address'],
      },
    );

    // Eventlistener for from address
    window.google.maps.event.addListener(this.autocompleteFromAddress, 'place_changed', () => {
      const place = this.autocompleteFromAddress.getPlace();

      this.setState({
        fromAddressPlace: place,
        fromAddress: place.formattedAddress,
      });
    });

    // Eventlistener for to address
    window.google.maps.event.addListener(this.autocompleteToAddress, 'place_changed', () => {
      const place = this.autocompleteToAddress.getPlace();

      this.setState({
        toAddress: place.formattedAddress,
        toAddressPlace: place,
      });
    });
  }

  /**
   * @author SimZor
   * @edited 2020-01-02 15:00
   */
  handleChange = (event) => {
    this.setState({ calculate: false });

    const { value } = event.target;

    // Update input
    this.setState({ [event.target.name]: value });
  }

  calculate = () => {
    const { fromAddressPlace, toAddressPlace } = this.state;

    // If BOTH from address place and to address place is defined
    if (fromAddressPlace && toAddressPlace) {
      // Get new directions service
      const directionsService = new window.google.maps.DirectionsService();

      // Get route
      directionsService.route({
        origin: { placeId: fromAddressPlace.place_id },
        destination: { placeId: toAddressPlace.place_id },
        travelMode: 'DRIVING',
      }, (response, status) => {
        if (status === 'OK') {
          const directionsData = response.routes[0].legs[0];
          this.setState({
            calculate: true,
            kilometersBetweenPlaces: Number(directionsData.distance.value / 1000).toFixed(1),
          });
        }
      });
    }
  }

  /**
   * @author SimZor
   * @edited 2020-01-02 15:00
   */
  movingCost = () => {
    const { kilometersBetweenPlaces, withRut, squareFeet } = this.state;

    // Base price
    let basePrice = 1500;
    if (withRut) { // Cut base price in half if rut
      basePrice /= 2;
    }

    // Calculate cubic meter price
    const cubicMeters = squareFeet * 0.34;
    let cubicMeterPrice = cubicMeters * PRICE_PER_CUBIC_METER;
    if (withRut) { // Cut cubic meter price in half if rut
      cubicMeterPrice /= 2;
    }

    // 100 kr for every square feet
    let squareFeetPrice = squareFeet * SQUARE_FEET_PRICE;
    if (squareFeet < 30) {
      squareFeetPrice = 30 * SQUARE_FEET_PRICE;
    }
    if (withRut) { // Cut square feet price in half if rut
      squareFeetPrice /= 2;
    }

    let kilometerPrice = kilometersBetweenPlaces * PRICE_PER_KILOMETER;
    if (kilometersBetweenPlaces < 50) { // Minimum of 100 kilometers / minimum price 300
      kilometerPrice = 300;
    }

    // Less or equal to 5 "mil" then no cubic meters, but base cost
    if (kilometersBetweenPlaces <= 500) {
      // Use
      // No cubic meter price
      // Square feet price
      // Base price
      cubicMeterPrice = 0;
    } else { // if more than 5 mil then cubic meter but no base cost
      // Use
      // Cubic meter price normal
      // No square feet price
      // No base price
      basePrice = 0;
      squareFeetPrice = 0;
    }

    // Handle RUT
    const rutDeduction = (
      cubicMeterPrice > 0
        ? basePrice + cubicMeterPrice
        : basePrice + squareFeetPrice
    );

    // After withRut
    if (withRut) {
      basePrice *= 2;
      cubicMeterPrice *= 2;
      squareFeetPrice *= 2;
    }

    const totalPrice = basePrice + cubicMeterPrice + kilometerPrice + squareFeetPrice;

    return {
      totalPrice,
      squareFeetPrice,
      cubicMeterPrice,
      kilometerPrice,
      cubicMeters,
      squareFeet,
      basePrice,
      kilometersBetweenPlaces,
      rutDeduction,
    };
  };

  /**
   * @author SimZor
   * @edited 2020-01-02 15:00
   */
  render() {
    const {
      fromAddress,
      toAddress,
      squareFeet,
      withRut,
      selectedPropertyType,
      calculate,
    } = this.state;

    const movingCost = this.movingCost();

    const currencyFormatter = new Intl.NumberFormat('sv-SE', {
      style: 'currency',
      currency: 'SEK',
      minimumFractionDigits: 0,
    });

    return (
      <>
        <div>
          <h1 className="title">Räkna ut kostnad för flytt</h1>
          <p style={{ textAlign: 'center' }}>Flytt från bostadstyp</p>
          <div
            className="buttonsContainer"
          >
            {/* eslint-disable-next-line */}
            <div
              className="buttonInContainerFirst buttonInContainer"
              onClick={() => this.setState({ selectedPropertyType: 'villa' })}
              style={{ backgroundColor: selectedPropertyType === 'villa' ? '#02a1f7' : 'white' }}
            >
              <i className="fa fa-home iconButton" aria-hidden="true" />
              <p>Villa</p>
            </div>
            {/* eslint-disable-next-line */}
            <div
              className="buttonInContainerFirst buttonInContainer"
              style={{ backgroundColor: selectedPropertyType === 'lägenhet' ? '#02a1f7' : 'white' }}
              onClick={() => this.setState({ selectedPropertyType: 'lägenhet' })}
            >
              <i className="fa fa-building iconButton" aria-hidden="true" />
              <p>Lägenhet</p>
            </div>
            {/* eslint-disable-next-line */}
            <div
              className="buttonInContainerFirst buttonInContainer"
              style={{
                marginRight: 0,
                backgroundColor: selectedPropertyType === 'radhus' ? '#02a1f7' : 'white',
              }}
              onClick={() => this.setState({ selectedPropertyType: 'radhus' })}
            >
              <i className="fa fa-home iconButton" aria-hidden="true" />
              <p>Radhus</p>
            </div>
          </div>
          <hr style={{ marginTop: 30 }} />
          <div className="inputContainer" style={{ marginTop: 30 }}>
            <div className="inputContainer-item-1">
              <label htmlFor="fromAdress">
                <input
                  type="text"
                  name="fromAddress"
                  value={fromAddress}
                  placeholder="Från adress"
                  ref={this.fromAddressRef}
                  onChange={this.handleChange}
                />
              </label>
            </div>
          </div>
          <div className="inputContainer">
            <div className="inputContainer-item-1">
              <label htmlFor="toAddress">
                <input
                  type="text"
                  name="toAddress"
                  value={toAddress}
                  ref={this.toAddressRef}
                  placeholder="Till adress"
                  onChange={this.handleChange}
                />
              </label>
            </div>
          </div>
          <div className="inputContainer">
            <div className="inputContainer-item-3">
              <label htmlFor="squareFeet">
                Kvadratmeter du flyttar ifrån
                <input
                  type="number"
                  name="squareFeet"
                  value={squareFeet}
                  onChange={this.handleChange}
                />
              </label>
            </div>
          </div>
          <div style={{ marginTop: 20 }} />
          <div className="inputContainer-label">
            <div className="inputContainer-item-1-label">
              <label htmlFor="withRut" className="checkbox">
                <input
                  type="checkbox"
                  name="withRut"
                  onChange={(event) => this.setState({ withRut: event.target.checked })}
                  checked={withRut}
                />
                <span>Jag vill utnyttja RUT-avdrag</span>
              </label>
            </div>
          </div>
          <button
            type="button"
            onClick={() => this.calculate()}
            style={{ marginTop: 20, backgroundColor: '#3a3a3a' }}
          >
            Se uträkning
          </button>
          <div style={{ marginTop: 30 }} />
          {
            (
              fromAddress !== ''
              && toAddress !== ''
              && squareFeet > 0
              && calculate
            ) && (
              <>
                <hr />
                <div style={{ marginTop: 30 }} />
                <div className="price-container" style={{ flexDirection: 'column' }}>
                  <div className="fadein">
                    <p style={{ width: '100%', marginTop: 0 }}>
                      Ungefärlig flyttkostnad&nbsp;
                      <span
                        style={{
                          color: '#02a1f7',
                          fontWeight: 600,
                        }}
                      >
                        {
                          withRut
                            ? `${currencyFormatter.format(movingCost.totalPrice.toFixed(0) - movingCost.rutDeduction.toFixed(0))}`
                            : currencyFormatter.format(movingCost.totalPrice)
                        }
                      </span>
                    </p>
                    <p style={{ fontSize: 15 }}>
                      Så har vi räknat:
                    </p>
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <td className="bold-text">{`Lastning och lossning - ${movingCost.squareFeet} kvadratmeter`}</td>
                          <td>
                            {
                              currencyFormatter.format(
                                movingCost.squareFeetPrice
                                  ? movingCost.squareFeetPrice + movingCost.basePrice
                                  : movingCost.cubicMeterPrice + movingCost.basePrice,
                              )
                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="bold-text">{`Transport - ${movingCost.kilometersBetweenPlaces} kilometer:`}</td>
                          <td>{currencyFormatter.format(movingCost.kilometerPrice.toFixed(0))}</td>
                        </tr>
                        {
                          withRut && (
                            <>
                              <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                              </tr>
                              <tr>
                                <td className="bold-text">Kostnad</td>
                                <td>
                                  {`${currencyFormatter.format(
                                    (((
                                      movingCost.squareFeetPrice
                                        ? movingCost.squareFeetPrice
                                        : movingCost.cubicMeterPrice
                                    ) + movingCost.kilometerPrice)
                                      + movingCost.basePrice).toFixed(0),
                                  )}`}
                                </td>
                              </tr>
                              <tr>
                                <td className="bold-text">RUT-avdrag</td>
                                <td>{`- ${currencyFormatter.format(movingCost.rutDeduction.toFixed(0))}`}</td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                              </tr>
                              <tr>
                                <td className="bold-text">Kostnad efter RUT-avdrag</td>
                                <td>{`${currencyFormatter.format(movingCost.totalPrice.toFixed(0) - movingCost.rutDeduction.toFixed(0))}`}</td>
                              </tr>
                            </>
                          )
                        }
                      </tbody>
                    </table>
                    <p style={{ fontSize: 15, fontStyle: 'italic' }}>
                      Info: Det uppskattade priset är baserat på en lättåtkomlig
                      normalt möblerad bostad på markplan utan förråd.
                      Ska ni flytta långdistans så är priset uppskattat för en samtransport.
                    </p>
                  </div>
                </div>
              </>
            )
          }
          { calculate && (
            <>
              <div style={{ marginBottom: 30 }} />
              <hr />
              <button
                type="button"
                onClick={() => window.open('https://flyttfirma.nu/offertforfragan/')}
                style={{ marginTop: 20 }}
              >
                Begär offert
              </button>
            </>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <a
            href="https://flyttfirma.nu"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src={FlyttfirmaLogo}
              alt="Flyttfirma.nu"
              width="200"
              style={{ padding: 30 }}
            />
          </a>
        </div>
      </>
    );
  }
}

export default App;
